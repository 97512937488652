import "./BeforeAfter.css";
import before from "../../assets/images/before.jpg";
import after from "../../assets/images/after.jpg";
import ServiceCard from "../ServiceCard/ServiceCard";
import ReactTwentytwenty from "react-twentytwenty";

const BeforeAfter = () => {
  return (
    <div className="before-container">
      <div className="card-container container">
        <div className="row">
          <ServiceCard />
        </div>
      </div>
      <div className="before-after-container">
        <ReactTwentytwenty
          left={<img src={after} alt="before" />}
          right={<img src={before} alt="after" />}
          slider={<div className="slider" />}
        />
      </div>
    </div>
  );
};

export default BeforeAfter;
