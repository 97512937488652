import { SiHomeassistantcommunitystore } from "react-icons/si";
export const companyData = [
  {
    counterEnd: 105,
    delayOfCounter: 6,
    text: "Projects Succeed",
    icon: <SiHomeassistantcommunitystore />,
  },
  {
    counterEnd: 105,
    delayOfCounter: 4,
    text: "Satisfied Client",
    icon: <SiHomeassistantcommunitystore />,
  },
  {
    counterEnd: 250,
    delayOfCounter: 8,
    text: "Professional Vendors",
    icon: <SiHomeassistantcommunitystore />,
  },
  {
    counterEnd: 3,
    delayOfCounter: 0,
    text: "Years Of Experience",
    icon: <SiHomeassistantcommunitystore />,
  },
];
