import ButtonComponent from "../Button/Button";
import "./ContactBanner.css";
export const ContactBanner = () => {
  return (
    <section>
      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action-inner">
            <div className="call-to-action-left">
              <div className="call-to-action-icon"></div>
              <div className="call-to-action-content">
                <p className="call-to-action-sub-title">
                  We are ready to help you
                </p>
                <h3 className="call-to-action-title">Need Any Design Help?</h3>
              </div>
            </div>
            <div className="call-to-action-btn-box mrt-md-30">
              <a href="tel:8279251471">
                <ButtonComponent bool={true} linkTitle="Contact Us" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
