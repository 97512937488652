import React from "react";
import "./Footer.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/Language";
import { BsArrowRight } from "react-icons/bs";
import Logo from "../../assets/images/logo.jpg";
import backgroundImageFirst from "../../assets/images/footer-obj1.png";
import backgroundImageSecond from "../../assets/images/footer-obj2.png";
import { Link } from "react-router-dom";
import { FooterData, SocialData } from "../../services/footer.service";

const Footer = () => {
  return (
    <footer className="footerTextColor">
      <img src={backgroundImageFirst} alt="" className="backgroundImage" />
      <div className="backgroundImageSecond">
        <img src={backgroundImageSecond} alt="" />
      </div>
      <div className="flex container footer-mobile">
        <div className="col-lg-6 d-flex gap-6 rxs">
          <div className="col-md-6 col-lg-6">
            <h5 className="footerHeading">ARCHYOGAM</h5>
            <p>
              As an interior design firm, Archyogam provides a range of services
              to help clients create functional and visually appealing spaces.
              This might include space planning, furniture selection, color
              scheme coordination, lighting design, and decor choices.
            </p>
          </div>
          <div className="col-md-6 col-lg-5">
            <h5 className="footerHeading">Services</h5>
            <ul className="servicesListItems">
              <li className="ListItem">
                <BsArrowRight />
                <Link to="/architecture">Architecture</Link>
              </li>

              <li className="ListItem">
                <BsArrowRight />
                <Link to="/interior-work"> Interior Design</Link>
              </li>
              {/* 
              <li className="ListItem">
                <BsArrowRight />
                <Link to="commercial-interior-design"> Commercial Design</Link>
              </li>

              <li className="ListItem">
                <BsArrowRight />
                <Link to="modular-kitchen"> Modular Kitchen</Link>
              </li> */}

              <li className="ListItem">
                <BsArrowRight />
                <Link to="furniture-design">Furniture Design</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 d-flex gap-6 rxs">
          <div className="col-md-6 col-lg-6">
            <h5 className="footerHeading">Contact</h5>
            <ul className="servicesListItems">
              <li className="lineHeight">
                <HomeIcon /> &nbsp;
                {FooterData.companyAddress}
              </li>
              <li className="flex gap-2 contactDetails cursor-pointer">
                <CallIcon />
                <a href="tel:8279-251471">{FooterData.phoneNumber}</a>
              </li>
              <li className="flex gap-2 contactDetails cursor-pointer">
                <EmailIcon />
                <a href="mailto:archyogam@gmail.com">
                  {FooterData.emailAddress}
                </a>
              </li>
              <li className="flex gap-2 contactDetails cursor-pointer">
                <LanguageIcon />
                <a
                  href={`${FooterData.domainName}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {FooterData.domainName}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-6 flex flex-col gap-4 pt-3">
            <img src={Logo} alt="" className="footer-logo" />
            <p className="lineHeight">
              We have 3+ years of experience for providing Architectural and
              interior service solutions.
            </p>
            <ul className="flex gap-2">
              {SocialData &&
                Array.isArray(SocialData) &&
                SocialData.map((item) => {
                  return (
                    <li className="socialmediaIcons" key={item.title}>
                      <a href={item.url} target="blank">
                        {item.icon}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className="copyRightCard">
        {`Copyright by ${FooterData.companyName} © ${FooterData.year}. All rights reserved`}
      </div>
    </footer>
  );
};

export default Footer;
