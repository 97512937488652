import React from "react";
import AboutCompany from "../components/AboutCompany/AboutCompany";
import AuthorDetails from "../components/AuthorDetails/AuthorDetails";
import BeforeAfter from "../components/BeforeAfter/BeforeAfter";
import CarouselSlider from "../components/Carousel/CarouselSlider";
import Categories from "../components/Categories/Categories";
import CompanyFacts from "../components/CompanyFacts/CompanyFacts";
import CompanyServices from "../components/CompanyServices/CompanyServices";
import { ContactBanner } from "../components/ContactBanner/ContactBanner";
import { HowWeWork } from "../components/HowWeWork/HowWeWork";
import LatestProject from "../components/LatestProjects/LatestProject";
import Testimonial from "../components/Testimonials/Testimonial";
import WhyToChooseUs from "../components/WhyToChooseUs/WhyToChooseUs";
import { carouselData } from "../services/carousel.service";

const Home = () => {
  return (
    <>
      <CarouselSlider carouselData={carouselData} />
      <Categories />
      <AboutCompany />
      <CompanyServices />
      <BeforeAfter />
      <HowWeWork />
      <AuthorDetails />
      <Testimonial />
      <LatestProject />
      <CompanyFacts />
      <WhyToChooseUs />
      <ContactBanner />
    </>
  );
};

export default Home;
