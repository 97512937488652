import { Link } from "react-router-dom";
import "../../../animation.css";
import "./ProjectItem.css";
const ProjectItem = (props) => {
  const { projectTitle, primaryImage, basicInfo, timestamp } = props.item;
  return (
    <div className="project-item-style1">
      <div className="project-item-thumb">
        <img className="img-full" src={primaryImage?.stringValue} alt="" />
        <div className="project-item-link-icon">
          <Link to={`${basicInfo?.stringValue + timestamp?.timestampValue}`}>
            &#8594;
          </Link>
        </div>
        <div className="project-item-details">
          <h6 className="project-item-category">{projectTitle?.stringValue}</h6>
          <h4 className="project-item-title">
            <Link to={`${basicInfo?.stringValue + timestamp?.timestampValue}`}>
              {projectTitle?.stringValue}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
