import "./Categories.css";
import { categories } from "../../services/categories.service";
const Categories = () => {
  return (
    <div className="categories-container">
      <div className="feature-box row">
        {categories &&
          Array.isArray(categories) &&
          categories.map((category) => {
            return (
              <div className="col-lg-2 col-md-4 col-sm-6" key={category.title}>
                <div className="category">
                  <div className="inner-box">
                    <div className="icon-box">{category.icon}</div>
                    <h6 className="title">{category.title}</h6>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Categories;
