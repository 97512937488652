import "./AuthorDetails.css";
import authorImg from "../../assets/images/WhatsApp Image 2022-12-28 at 2.29.10 PM.jpeg";
import Heading from "../Heading/Heading";
import { VscQuote } from "react-icons/vsc";
import { SocialData } from "../../services/footer.service";

const AuthorDetails = () => {
  return (
    <div className="author-box">
      <div className="container">
        <Heading
          heading="Meet Our Team"
          subHeading1="Let's See Our"
          subHeading2="Architect"
          highlight="Principal"
        />
        <div className="row flex align-items-center justify-center flex-lg-row">
          <div className="author-block col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div className="author-upper-part">
              <img className="img-full" src={authorImg} alt="author" />
              <ul className="social-list vertical-style list-sm">
                {SocialData &&
                  Array.isArray(SocialData) &&
                  SocialData.map((item) => {
                    return (
                      <li key={item.title}>
                        <a href={item.url}>{item.icon}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="author-bottom-part">
              <h4 className="author-title">Namita Sharma</h4>
              <h6 className="designation">Principal architect</h6>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
            <p className="desc">
              <VscQuote />
              ARCHYOGAM– is leading design firm, that specialises in residential
              and commercial designs and has established its presence throughout
              the country. Known for simplicity in her design approach,
              Architect Namita Sharma juxtaposes the orthogonal nature of clean
              lines with a luxurious mix of materials colours and texture in
              order to create a distinct look for each project. She strongly
              believes that no space is too small to be designed.
              <VscQuote />
            </p>
            <p className="desc">
              Also she is a true follower of “ LESS IS MORE” That is minimalism
              , which can clearly be noticed in her designs. .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorDetails;
