import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Header/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import Projects from "./pages/Projects/Projects";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import { projectsList } from "./services/Projects.services";
import Contact from "./pages/Contact/Contact";
import ServiceType from "./pages/ServiceTypes/ServiceType";
import About from "./pages/About/About";
import Error from "./pages/Error";
import { NavBarItems } from "./services/header.service";
import { serviceTypeData } from "./services/ServiceType.service";
import React, { useEffect, useState } from "react";
import ProjectsDetailsForm from "./components/ProjectsDetailsForm/ProjectsDetailsForm";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase.config";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  const [projectsData, setProjectsData] = useState([]);
  const getData = async () => {
    const blogRef = collection(db, "Projects");
    const querySnapshot = await getDocs(blogRef);
    setProjectsData(querySnapshot.docs);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/create-archnam-project"
          element={<ProjectsDetailsForm />}
        />
        <Route path="/about" element={<About />} />
        {projectsData &&
          Array.isArray(projectsData) &&
          projectsData.map((item) => {
            const finalItem = item?._document?.data?.value?.mapValue?.fields;
            return (
              <Route
                key={finalItem?.id?.stringValue}
                path={`projects/${
                  finalItem?.basicInfo?.stringValue +
                  finalItem?.timestamp?.timestampValue
                }`}
                element={
                  <ProjectDetails
                    item={finalItem}
                    length={projectsData.length}
                  />
                }
              />
            );
          })}
        {NavBarItems &&
          NavBarItems.map((headerListItem) => {
            return (
              <React.Fragment key={headerListItem.id}>
                {headerListItem.submenu &&
                  headerListItem.submenu.map((subListItem) => {
                    return (
                      <Route
                        key={subListItem.id}
                        path={subListItem.slug}
                        element={
                          <ServiceType
                            slug={subListItem.slug}
                            serviceTypeData={serviceTypeData}
                          />
                        }
                      />
                    );
                  })}
              </React.Fragment>
            );
          })}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
