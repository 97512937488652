import { PlayCircle } from "@mui/icons-material";
import React from "react";
import Heading from "../Heading/Heading";
import "./whatWeOffer.css";
import "../../animation.css";
import aboutimg from "../../assets/images/divider-img1.jpg";
import Categories from "../Categories/Categories";

const WhatWeOffer = () => {
  return (
    <section className="whatweoffer">
      <div className="section-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-6">
              <Heading
                heading="What We Offer"
                highlight="Our Company"
                subHeading1="Make You"
                subHeading2="Feel More Confident"
              />
              <p className="desc">
                At Archyogam , we feel your emotions towards your space , and
                provide you with the most functional and user friendly design
                while keeping your budget in mind{" "}
              </p>
              <div className="video-block mrb-lg-60">
                <div className="video-link">
                  <a
                    target="_blank"
                    className="video-popup"
                    href="https://www.youtube.com/@Archyogam"
                    rel="noreferrer"
                  >
                    <PlayCircle />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-6">
              <div className="shine-effect">
                <img className="img-full" src={aboutimg} alt="" />
              </div>
            </div>
          </div>
          <div className="row whatweoffer-category-container">
            <Categories />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
