import imgOne from "../assets/images/avtarrr.avif";
export const TestimonialCardData = [
  {
    img: imgOne,
    desc: `I am very fortuitous to have availed architectural consultancy, formidable architectural working experience in all streams whether it be architecture, interiors, landscape or conservation.`,
    name: "Suny",
    desgination: "Chaturvedi",
  },
  {
    img: imgOne,
    desc: `The idea for designing Architecture are so great. All information and requirements properly explained and guided perfectly. Best place for Designing the dreams into picture 😍🤩😍🤩`,
    name: "Parag",
    desgination: "Sharma",
  },
  {
    img: imgOne,
    desc: `I think it's s a genuine place for one looking for professional services to have there dream house come into reality. The creater is so energetic and an enthusiastas for her profession.`,
    name: "Pankaj",
    desgination: "Bothra",
  },
  {
    img: imgOne,
    desc: `A place where u can fulfill your dream house even more beautiful. The creator has magic in her hands which makes her work even more beautiful. And trust me she do all the works with full passion.`,
    name: "Abhinav",
    desgination: "Kumar",
  },
  {
    img: imgOne,
    desc: `A perfect fit for getting the space of your dreams... Completely reliable and trustworthy. A great combination of professionalism, creativity and enthusiasm.`,
    name: "Harshil",
    desgination: "Agrawal",
  },
  {
    img: imgOne,
    desc: `Experience with Archyogam is always best at time. Absolutely trustable and Best quality of the work. thank you Archyogam Team :)😍🤩 Best Work....`,
    name: "Nikhil",
    desgination: "Kumar",
  },
];
