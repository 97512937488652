import gymmain from "../assets/images/gymmain.jpg";
import kitchenmain from "../assets/images/kitchenmain.jpg";
import newimage from "../assets/images/new.jpeg";
import bed1main from "../assets/images/bed1main.jpg";
import bed2main from "../assets/images/bed2main.jpg";
import bed3main from "../assets/images/bedmain.jpg";
import salonmain from "../assets/images/salonmain.jpg";
import homemain from "../assets/images/homemain.jpg";

export const latestProjectData = [
  {
    img: gymmain,
    title: "Architecture",
    link: "Interior work",
    route: "/projects/1",
  },
  {
    img: kitchenmain,
    title: "Modular Kitchen",
    link: "Interior work",
    route: "/projects/2",
  },
  {
    img: newimage,
    title: "Commercial",
    link: "Interior work",
    route: "/projects/3",
  },
  {
    img: bed1main,
    title: "Interior",
    link: "Interior work",
    route: "/projects/4",
  },
  {
    img: bed2main,
    title: "Interior",
    link: "Interior work",
    route: "/projects/5",
  },
  {
    img: bed3main,
    title: "Interior",
    link: "Interior work",
    route: "/projects/5",
  },
  {
    img: salonmain,
    title: "Architeecture",
    link: "Interior work",
    route: "/projects/6",
  },
  {
    img: homemain,
    title: "Residential",
    link: "Interior work",
    route: "/projects/7",
  },
  {
    img: gymmain,
    title: "Interior",
    link: "Interior work",
    route: "/projects/1",
  },
];
