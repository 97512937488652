import React from "react";
import "./Button.css";
import Button from "@mui/material/Button";

const ButtonComponent = ({ linkTitle, bool }) => {
  return (
    <Button
      variant="text"
      className={`primary-btn ${bool ? "secondary-btn" : ""}`}
    >
      {linkTitle}
    </Button>
  );
};

export default ButtonComponent;
