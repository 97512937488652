import {
  ButtonBack,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { BsChevronLeft } from "react-icons/bs";
import "../../animation.css";
import { latestProjectData } from "../../services/LatestProject.service";
import {
  responsiveCarousal,
  visibleSlideLatest,
} from "../../utils/responsiveCarousel";
import Heading from "../Heading/Heading";
import "./LatestProject.css";

const LatestProject = () => {
  return (
    <div className="latest-project-container">
      <div className="container">
        <div className="top-heading">
          <Heading
            heading="Our Projects"
            subHeading1="Our Outstanding"
            highlight="Latest Proejcts"
            subHeading2="& Works"
          />
          <p>
            We are stronger because of our collaborative spirit, our shared
            values, our diverse perspectives, and our One-Firm Firm culture. By
            working seamlessly as an interconnected firm, we’re able to make the
            greatest impact for our global communities as we continue to tackle
            the challenges.
          </p>
        </div>
      </div>
      <div className="container mlcustom">
        <div className="col-md-12">
          <CarouselProvider
            visibleSlides={visibleSlideLatest(4)}
            totalSlides={9}
            step={1}
            naturalSlideWidth={400}
            naturalSlideHeight={500}
            infinite={true}
          >
            <Slider>
              {latestProjectData &&
                Array.isArray(latestProjectData) &&
                latestProjectData.map((item, index) => {
                  return (
                    <Slide
                      className="latesprojectslide"
                      style={{
                        paddingBottom: `${responsiveCarousal()}`,
                      }}
                      key={item.title + index}
                      index={index}
                    >
                      <div className="project-item-style1">
                        <div className="project-item-thumb">
                          <img className="img-full" src={item.img} alt="" />
                          <div className="project-item-details">
                            <h4 className="project-item-category">
                              {item.title}
                            </h4>
                          </div>
                        </div>
                      </div>{" "}
                    </Slide>
                  );
                })}
            </Slider>
            <ButtonBack className="backbtn">
              <BsChevronLeft />
            </ButtonBack>
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
};

export default LatestProject;
