import React from "react";
import AboutCompany from "../../components/AboutCompany/AboutCompany";
import AuthorDetails from "../../components/AuthorDetails/AuthorDetails";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { ContactBanner } from "../../components/ContactBanner/ContactBanner";
import WhatWeOffer from "../../components/whatWeOffer/WhatWeOffer";

const About = () => {
  return (
    <>
      <BreadCrumb heading="About Us" subheading="Home" link=" About Us" />
      <AboutCompany />
      <WhatWeOffer />
      <AuthorDetails />
      <ContactBanner />
    </>
  );
};

export default About;
