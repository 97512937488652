import { FaSitemap } from "react-icons/fa";
import { GiPlantRoots } from "react-icons/gi";
import { MdOutlineDesignServices, MdOutlineHomeWork } from "react-icons/md";

export const StepsCardData = [
  {
    count: "01",
    icon: <FaSitemap />,
    title: "Site Project",
    subTitle: "Site visit to understand the context of the site.",
  },
  {
    count: "02",
    icon: <GiPlantRoots />,
    title: "Planning Design",
    subTitle: "Conceptual planning for the first discussion.",
  },
  {
    count: "03",
    icon: <MdOutlineDesignServices />,
    title: "Design intervention",
    subTitle: "Design progress as per the functionality and taste of user.",
  },
  {
    count: "04",
    icon: <MdOutlineHomeWork />,
    title: "Execution",
    subTitle: "Implementation of the design with our skilled team",
  },
];
