import { Slider, Typography } from "@material-ui/core";
import React from "react";

const RangeSlider = ({ defaultValue, Label }) => {
  return (
    <div>
      <Typography>{Label}</Typography>
      <Slider valueLabelDisplay="off" value={defaultValue} />
    </div>
  );
};

export default RangeSlider;
