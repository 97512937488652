import React from "react";
import "./StepsCard.css";
import { StepsCardData } from "../../services/StepsCard.service";

const StepsCard = () => {
  return (
    <>
      {StepsCardData &&
        Array.isArray(StepsCardData) &&
        StepsCardData.map((item) => {
          return (
            <div key={item.title} className="process-item col-lg-3">
              <div className="process-icon-box">
                <div className="process-icon">{item.icon}</div>
                <div className="process-count">{item.count}</div>
              </div>
              <div className="process-details">
                <h4 className="process-title">{item.title}</h4>
                <p className="process-text">{item.subTitle}</p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default StepsCard;
