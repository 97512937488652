import "../../animation.css";
import "./AboutCompany.css";
import smallImage from "../../assets/images/about-sm1.jpg";
import largeImage from "../../assets/images/about-lg1.jpg";
import CheckIcon from "@mui/icons-material/Check";
import Tilt from "react-parallax-tilt";
import { featuresData, listboxData } from "../../services/AboutCompany.service";
import Heading from "../Heading/Heading";

const AboutCompany = () => {
  return (
    <div className="main-container">
      <div className="container">
        <div className="data-box">
          <div className="left-box">
            <Tilt className="small-img-box">
              <img className="small-img" src={smallImage} alt={smallImage} />
            </Tilt>
            <div className="large-img-box">
              <img className="large-img" src={largeImage} alt={largeImage} />
            </div>
          </div>
          <div className="right-box">
            <Heading
              heading="About Our Company"
              subHeading1="Providing the best"
              subHeading2="& interior design services"
              highlight="architecture"
            />
            <p className="about-text-block">
              Transforming spaces with creativity{" "}
            </p>
            <div className="listbox">
              {listboxData &&
                Array.isArray(listboxData) &&
                listboxData.map((item) => {
                  return (
                    <ul key={item.titleOne}>
                      <li>
                        <CheckIcon />
                        {item.titleOne}
                      </li>
                      <li>
                        <CheckIcon />
                        {item.titleTwo}
                      </li>
                    </ul>
                  );
                })}
            </div>
            <div className="yoe-box">
              <div className="feature-box-container">
                {featuresData &&
                  Array.isArray(featuresData) &&
                  featuresData.map((item) => {
                    return (
                      <div className="features-box" key={item.title}>
                        <div className="feature-icon">{item.icon}</div>
                        <div className="feature-content">
                          <h4 className="featured-title">{item.title}</h4>
                          <p className="featured-desc">{item.subtitle}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="experience">
                <p className="experience-text">
                  We have more than years of experience
                </p>
                <h4 className="experience-year">03+</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
