import React from "react";
import mainImage from "../../assets/images/about-lg2.jpg";
import "./WhyToChooseUs.css";
import Heading from "../../components/Heading/Heading";
import CheckIcon from "@mui/icons-material/Check";
import RangeSlider from "../RangeSlider/RangeSlider";
import { RangeSliderProps } from "../../services/WhyToChooseUs.service";
import { listItems } from "../../services/WhyToChooseUs.service";
import Tilt from "react-parallax-tilt";
import leftImage from "../../assets/images/about-sm2.jpg";

const WhyToChooseUs = () => {
  const headerProps = {
    heading: "Why Choose Us",
    subHeading1: `We Provide Unique`,
    subHeading2: "Design",
    highlight: "Creative",
  };
  return (
    <div className="whyToChooseUsSection">
      <div className="flex container justify-center">
        <div className="row mainwhyus">
          <div className="whyToChooseUsImages relative">
            <Tilt className="tilt">
              <img
                src={leftImage}
                alt=""
                className="w-40 rounded-tl-2xl rounded-br-2xl secondimg"
              />
            </Tilt>
            <img
              src={mainImage}
              alt=""
              className="rounded-tl-2xl rounded-br-2xl w-80"
            />
          </div>
          <div className="whyToChooseUsRightSection">
            <Heading {...headerProps} />
            <p>
              Unique and functionality oriented designs which go hand in hand
              with contemporary architecture.
            </p>
            <div className="flex gap-6 video-range">
              <div>
                <ul className="gap-2 flex flex-col">
                  {listItems.map((item) => {
                    return (
                      <li className="flex items-center gap-2">
                        <CheckIcon
                          fontSize="15px"
                          sx={{ color: "#b98e75", fontWeight: "900" }}
                        />
                        {item.data}
                      </li>
                    );
                  })}
                </ul>
                <ul>
                  <div className="rangeSliderContainer flex-1">
                    {RangeSliderProps?.map((props) => {
                      return (
                        <li className="rangeSlider">
                          <RangeSlider {...props} />
                        </li>
                      );
                    })}
                  </div>
                </ul>
              </div>
              <div className="video-frame">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/pyX_THBpsCc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyToChooseUs;
