let windowWidth = window.innerWidth;
export function responsiveCarousal() {
  if (
    (windowWidth >= 1200 && windowWidth < 1400) ||
    (windowWidth <= 991 && windowWidth >= 768)
  )
    return "17.833%";
  else if (windowWidth >= 992 && windowWidth < 1199) return "19.833%";
  else if (windowWidth < 400) return "16.833%";
  return "14.8333%";
}
export function responsiveCarousalnew() {
  if (
    (windowWidth >= 1200 && windowWidth < 1400) ||
    (windowWidth <= 991 && windowWidth >= 768)
  )
    return "17.833%";
  else if (windowWidth >= 992 && windowWidth < 1199) return "19.833%";
  else if (windowWidth < 700) return "19.833%";
  return "14.8333%";
}

export function visibleSlide(num) {
  if (windowWidth <= 991 && windowWidth > 767) return 2;
  else if (windowWidth <= 767) return 1;
  return num;
}

export function visibleSlidenew(num) {
  if (windowWidth <= 991 && windowWidth > 320) return 1;
  return num;
}

export function visibleSlideLatest(num) {
  if (windowWidth <= 991 && windowWidth > 767) return 2;
  else if (windowWidth <= 767) return 2;
  return num;
}
