// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAEJIWpEu6189D-x8wOwNzyw3fhaPdz_Rs",
  authDomain: "archyogam-adc89.firebaseapp.com",
  projectId: "archyogam-adc89",
  storageBucket: "archyogam-adc89.appspot.com",
  messagingSenderId: "828760536933",
  appId: "1:828760536933:web:0f18555386c36493f6138c",
  measurementId: "G-FRFHTCVCKG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);
