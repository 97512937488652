import "../../animation.css";
import "./HowWeWork.css";
import processShape from "../../assets/images/working-process-shape.png";
import StepsCard from "../StepsCard/StepsCard";
export const HowWeWork = () => {
  return (
    <div className="container-box">
      <div className="container">
        <div className="title-box">
          <h5>How We Works</h5>
          <h2>Our Easy Working Steps</h2>
          <div className="big-text">Working</div>
        </div>
      </div>
      <div className="container second-container">
        <div className="working-process-wrapper">
          <div className="working-process-shape">
            <img src={processShape} alt="" />
          </div>
          <div className="process-item-container row">
            <StepsCard />
          </div>
        </div>
      </div>
    </div>
  );
};
