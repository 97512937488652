import React from "react";
import "./TestimonialCard.css";
import { VscQuote } from "react-icons/vsc";

const TestimonialCard = (props) => {
  const { img, name, desgination, desc } = props.item;
  return (
    <div className="testimonial-item">
      <VscQuote />
      <div className="testimonial-thumb">
        <img src={img} alt="" />
      </div>
      <div className="testimonial-content">
        <p className="comments">{desc} </p>
        <h4 className="client-name">{name}</h4>
        <h6 className="client-designation">{desgination}</h6>
      </div>
    </div>
  );
};

export default TestimonialCard;
