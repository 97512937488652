import { CircularProgress, TextareaAutosize, TextField } from "@mui/material";
import React, { useState } from "react";
import "./ProjectsDetailsForm.css";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase.config";
import { v4 as uuidv4 } from "uuid";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
const ProjectsDetailsForm = () => {
  const [fileFormData, setFileFormData] = useState({
    primaryImage: "",
    secondaryImage: "",
  });
  const [progress, setProgress] = useState(0);
  const [runningState, setRunningState] = useState("");
  const storage = getStorage();
  const [formData, setFormData] = useState({
    projectDate: "",
    clientName: "",
    categories: "",
    budget: "",
    basicInfo: "",
    challenge: "",
    result: "",
    primaryImage: "",
    secondaryImage: "",
    projectTitle: "",
    pointOne: "",
    pointTwo: "",
    pointThree: "",
    pointFour: "",
    firstImage: "",
    secondImage: "",
    thirdImage: "",
    fourthImage: "",
    fifthImage: "",
    id: uuidv4(),
  });
  const disable =
    formData.projectTitle === "" ||
    formData.projectDate === "" ||
    formData.clientName === "" ||
    formData.categories === "" ||
    formData.basicInfo === "" ||
    formData.pointOne === "" ||
    formData.pointTwo === "" ||
    formData.pointThree === "" ||
    formData.fourthImage === "" ||
    formData.result === "" ||
    formData.primaryImage === "" ||
    formData.firstImage === "" ||
    formData.secondImage === "" ||
    formData.thirdImage === "" ||
    formData.fourthImage === "" ||
    formData.fifthImage === "";
  const onSubmitDetails = async (e) => {
    e.preventDefault();
    const response = await addDoc(collection(db, "Projects"), {
      ...formData,
      timestamp: serverTimestamp(),
    });
    setFormData({
      id: uuidv4(),
      projectDate: "",
      clientName: "",
      categories: "",
      budget: "",
      basicInfo: "",
      challenge: "",
      result: "",
      pointOne: "",
      pointTwo: "",
      pointThree: "",
      pointFour: "",
      firstImage: "",
      secondImage: "",
      thirdImage: "",
      fourthImage: "",
      fifthImage: "",
      projectTitle: "",
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFileFormData({
      ...fileFormData,
      [e.target.files[0].name]: e.target.files[0],
    });
    const storageRef = ref(storage, e.target.files[0].name);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            setRunningState("running");
            break;
          default:
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({ ...formData, [e.target.name]: downloadURL });
        });
      }
    );
  };
  return (
    <>
      <BreadCrumb
        link="Create Projcet"
        heading="Project Details"
        subheading="Home"
      />
      {runningState === "running" && progress !== 100 ? (
        <div className="circular-progress">
          <CircularProgress />
        </div>
      ) : (
        <div className="mt-20  justify-center flex projectDetailsContainer">
          <form
            onSubmit={onSubmitDetails}
            className="flex flex-col gap-4 border border-indigo-600 p-3 w-50 sm:w-full md:w-full items-center mb-4  project-form-details"
          >
            <h1>Create New Project</h1>

            <div className="flex gap-4 input-parent-box">
              <TextField
                required
                name="projectTitle"
                label="Project Title"
                onChange={(e) => handleChange(e)}
                value={formData.projectTitle}
              />
            </div>
            <div className="flex gap-4 input-parent-box">
              <TextField
                required
                name="projectDate"
                label="Project Date"
                onChange={(e) => handleChange(e)}
                value={formData.projectDate}
              />
            </div>
            <div className="flex gap-4 input-parent-box">
              <TextField
                required
                name="clientName"
                label="Location"
                onChange={(e) => handleChange(e)}
                value={formData.clientName}
              />
            </div>

            <div className="flex gap-4 input-parent-box">
              <TextField
                required
                name="categories"
                label="Categories"
                onChange={(e) => handleChange(e)}
                value={formData.categories}
              />
            </div>
            {/* <div className="flex gap-4 input-parent-box">
              <TextField
                name="budget"
                label="Budget"
                onChange={(e) => handleChange(e)}
                value={formData.budget}
              />
              </div>
              
               */}
            <div className="flex gap-4 input-parent-box">
              <TextareaAutosize
                required
                placeholder="Basic Info"
                className="form-textarea"
                minRows={10}
                name="basicInfo"
                label="Basic Info"
                onChange={(e) => handleChange(e)}
                value={formData.basicInfo}
              />
            </div>
            <div className="flex gap-4 input-parent-box feature-parent">
              <h6>Features</h6>
              <TextField
                required
                name="pointOne"
                label="First"
                onChange={(e) => handleChange(e)}
                value={formData.pointOne}
              />
              <TextField
                required
                name="pointTwo"
                label="Second"
                onChange={(e) => handleChange(e)}
                value={formData.pointTwo}
              />
              <TextField
                required
                name="pointThree"
                label="Third"
                onChange={(e) => handleChange(e)}
                value={formData.pointThree}
              />
              <TextField
                required
                name="pointFour"
                label="Fourth"
                onChange={(e) => handleChange(e)}
                value={formData.pointFour}
              />
            </div>
            {/* <div className="flex gap-4 input-parent-box">
              <TextareaAutosize
                required
                placeholder="Challenge"
                className="form-textarea"
                minRows={10}
                name="challenge"
                label="Challenges"
                onChange={(e) => handleChange(e)}
                value={formData.challenge}
              />
            </div> */}
            <div className="flex gap-4 input-parent-box">
              <TextareaAutosize
                required
                placeholder="Results"
                className="form-textarea"
                minRows={10}
                name="result"
                label="Results"
                onChange={(e) => handleChange(e)}
                value={formData.result}
              />
            </div>
            <div
              className="primary img-box"
              style={{
                background: formData.primaryImage === "" ? "red" : "green",
              }}
            >
              <label htmlFor="pri">
                {progress === 100 && formData.primaryImage !== ""
                  ? "Uploaded"
                  : "Primary image"}
                <input
                  className="d-none"
                  id="pri"
                  type="file"
                  onChange={handleFileChange}
                  placeholder="upload primary Image"
                  name="primaryImage"
                  value={fileFormData.primaryImage}
                />
              </label>
            </div>
            <div className="flex gap-4 input-parent-box feature-parent">
              <h6>Slider Images</h6>
              <div
                className="Secondary img-box"
                style={{
                  background: formData.firstImage === "" ? "red" : "green",
                }}
              >
                <label htmlFor="firstImage">
                  {progress === 100 && formData.firstImage !== ""
                    ? "Uploaded"
                    : "First image"}
                  <input
                    className="d-none"
                    type="file"
                    onChange={handleFileChange}
                    name="firstImage"
                    id="firstImage"
                    value={fileFormData.secondaryImage}
                  />
                </label>
              </div>
              <div
                className="Secondary img-box"
                style={{
                  background: formData.secondImage === "" ? "red" : "green",
                }}
              >
                <label htmlFor="secondImage">
                  {progress === 100 && formData.secondImage !== ""
                    ? "Uploaded"
                    : "Second image"}
                  <input
                    className="d-none"
                    type="file"
                    onChange={handleFileChange}
                    name="secondImage"
                    id="secondImage"
                    value={fileFormData.secondaryImage}
                  />
                </label>
              </div>
              <div
                className="Secondary img-box"
                style={{
                  background: formData.thirdImage === "" ? "red" : "green",
                }}
              >
                <label htmlFor="thirdImage">
                  {progress === 100 && formData.thirdImage !== ""
                    ? "Uploaded"
                    : "Third image"}
                  <input
                    className="d-none"
                    type="file"
                    onChange={handleFileChange}
                    name="thirdImage"
                    id="thirdImage"
                    value={fileFormData.secondaryImage}
                  />
                </label>
              </div>
              <div
                className="Secondary img-box"
                style={{
                  background: formData.fourthImage === "" ? "red" : "green",
                }}
              >
                <label htmlFor="fourthImage">
                  {progress === 100 && formData.fourthImage !== ""
                    ? "Uploaded"
                    : "Fourt image"}
                  <input
                    className="d-none"
                    type="file"
                    onChange={handleFileChange}
                    name="fourthImage"
                    id="fourthImage"
                    value={fileFormData.secondaryImage}
                  />
                </label>
              </div>
              <div
                className="Secondary img-box"
                style={{
                  background: formData.fifthImage === "" ? "red" : "green",
                }}
              >
                <label htmlFor="fifthImage">
                  {progress === 100 && formData.fifthImage !== ""
                    ? "Uploaded"
                    : "Fifth image"}
                  <input
                    className="d-none"
                    type="file"
                    onChange={handleFileChange}
                    name="fifthImage"
                    id="fifthImage"
                    value={fileFormData.secondaryImage}
                  />
                </label>
              </div>
            </div>
            <button
              disabled={disable}
              type="submit"
              className="form-btn"
              style={{ background: disable ? "gray" : "#b98e75" }}
            >
              {disable ? "Fill all the Inputs and Upload Images" : "Submit"}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default ProjectsDetailsForm;
