import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import "./ServiceType.css";
import CallIcon from "@mui/icons-material/Call";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Check, ChevronRight } from "@mui/icons-material";
import { BsCloudDownloadFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Home from "../Home";

const ServiceType = ({ slug, serviceTypeData }) => {
  const architecture = serviceTypeData[0];
  const residential = serviceTypeData[1];
  // const commercial = serviceTypeData[2];
  // const modularkitchen = serviceTypeData[3];
  const furniture = serviceTypeData[2];
  const result = () => {
    if (slug === "architecture") {
      return architecture;
    } else if (slug === "interior-work") {
      return residential;
    }
    // else if (slug === "commercial-interior-design") {
    // return commercial;
    // }
    // else if (slug === "modular-kitchen") {
    // return modularkitchen;
    // }
    else if (slug === "furniture-design") {
      return furniture;
    } else {
      return <Home />;
    }
  };
  const data = result();
  return (
    <>
      <BreadCrumb
        heading="Architecture"
        subheading="Home"
        link={`${slug.toUpperCase()}`}
      />
      {window.innerWidth <= 991 && (
        <div class="service-nav-menu mrb-30 container mx-auto">
          <div class="service-link-list">
            <ul>
              <li class="active">
                <Link to="/architecture">
                  <ChevronRight />
                  Architecture
                </Link>
              </li>
              <li>
                <Link to="/interior-work">
                  <ChevronRight />
                  Interior Design
                </Link>
              </li>
              {/* <li>
                <Link to="/commercial-interior-design">
                  <ChevronRight />
                  Commercial Design
                </Link>
              </li>
              <li>
                <Link to="/modular-kitchen">
                  <ChevronRight />
                  Modular Kitchen
                </Link>
              </li> */}
              <li>
                <Link to="/furniture-design">
                  <ChevronRight />
                  Furniture Design
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      <section class="service-details-page pdt-110 pdb-110 pdb-lg-75">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7">
              <div class="service-detail-text">
                <div class="blog-standared-img slider-blog mrb-40">
                  <img class="img-full" src={data.mainImg} alt="" />
                </div>
                <h3 class="mrb-15">Service Details</h3>
                <p class="mrb-40">{data.basicInfo} </p>
                <p class="about-text-block mrb-40">{data.highlight}</p>
                <p class="mrb-35">{data.desc} </p>
                <div class="service-details-content">
                  <div class="row mrb-30">
                    <div class="col-lg-12">
                      <h3 class="mrb-30">Frequently Asked Question</h3>
                    </div>
                    <div class="col-lg-12">
                      <div class="faq-block">
                        <Accordion className="accordion-item">
                          <AccordionSummary
                            className="title"
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{data.faq[0].ques}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{data.faq[0].ans} </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="accordion-item">
                          <AccordionSummary
                            className="title"
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{data.faq[1].ques}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{data.faq[1].ans}</Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="accordion-item">
                          <AccordionSummary
                            className="title"
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{data.faq[2].ques}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{data.faq[2].ans}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex service-list">
                    <div class="col-lg-12 col-xl-6 pdl-40 pdl-lg-15">
                      <h3 class="mrb-20">Service Included</h3>
                      <ul className="order-list primary-color mrt-lg-30 mrt-md-15">
                        <li>
                          <Check />
                          {data.listOptions[0]}
                        </li>
                        <li>
                          <Check />
                          {data.listOptions[1]}
                        </li>
                        <li>
                          <Check />
                          {data.listOptions[2]}
                        </li>
                        <li>
                          <Check />
                          {data.listOptions[3]}
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-12 col-xl-6">
                      <img
                        class="img-full mrb-lg-35"
                        src={data.secImg}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-5 sidebar-right">
              {window.innerWidth > 991 && (
                <div class="service-nav-menu mrb-30">
                  <div class="service-link-list">
                    <ul>
                      <li class="active">
                        <Link to="/architecture">
                          <ChevronRight />
                          Architecture
                        </Link>
                      </li>
                      <li>
                        <Link to="/interior-work">
                          <ChevronRight />
                          Interior Design
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/commercial-interior-design">
                          <ChevronRight />
                          Commercial Design
                        </Link>
                      </li>
                      <li>
                        <Link to="/modular-kitchen">
                          <ChevronRight />
                          Modular Kitchen
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/furniture-design">
                          <ChevronRight />
                          Furniture Design
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <div class="sidebar-widget-need-help">
                <div class="need-help-icon">
                  <CallIcon />
                </div>
                <h4 class="need-help-title">
                  Get Easy {data.type} Solution <br /> From Us
                </h4>
                <div class="need-help-contact">
                  <p class="mrb-5">Please Contact With Us</p>
                  <a href="tel:8279251471">8279-251471</a>
                </div>
              </div>
              {/* <div class="sidebar-widget">
                <div class="brochure-download">
                  <h4 class="mrb-40 widget-title">Brochure Download</h4>
                  <p>Please click download button for getting brochure file</p>
                  <a href="#" class="cs-btn-one">
                    <BsCloudDownloadFill /> Download PDF
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceType;
