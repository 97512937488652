import Logo from "../assets/images/logo.jpg";
export const headerData = {
  logo: Logo,
  phoneNumber: "archyogam@gmail.com",
};

export const NavBarItems = [
  {
    id: "home",
    name: "Home",
    slug: "",
  },
  {
    id: "about",
    name: "About",
    slug: "about",
  },
  {
    id: "services",
    name: "Services",
    submenu: [
      {
        id: "architecture",
        name: "Architectural design",
        slug: "architecture",
      },
      {
        id: "interior-work",
        name: "Interior design",
        slug: "interior-work",
      },
      // {
      //   id: "commercial-interior-design",
      //   name: "Commercial interior design",
      //   slug: "commercial-interior-design",
      // },
      // {
      //   id: "modular-kitchen",
      //   name: "Modular kitchen",
      //   slug: "modular-kitchen",
      // },
      {
        id: "furniture-design",
        name: "Furniture design",
        slug: "furniture-design",
      },
    ],
  },
  {
    id: "project",
    name: "Projects",
    slug: "projects",
  },
  {
    id: "contact",
    name: "Contact",
    slug: "contact",
  },
];
