import "./FourOFour.css";
import { Link } from "react-router-dom";

const FourOFour = () => {
  return (
    <div className="error-area">
      <div className="container z-10">
        <div className="row">
          <div className="col-xl-12">
            <div className="error-inner text-center">
              <h1 className="error-title">
                4<span className="text-primary-color">0</span>4
              </h1>
              <h2 className="error-text">Sorry, something went wrong!</h2>
              <p>
                This page is temporarily unavailable due to maintenance. We will
                back very soon thanks for your patien
              </p>
              <Link to="/" className="cs-btn-one btn-md btn-primary-color">
                Return Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourOFour;
