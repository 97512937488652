import { HiMap, HiPhone, HiMail, HiDotsCircleHorizontal } from "react-icons/hi";
export const contactPageData = [
  {
    icon: <HiMap />,
    title: "Reach Out",
    desc: "4/5, Vivekanand Nagar , Alwar (Rajasthan)",
  },
  {
    icon: <HiPhone />,
    title: "Call Us",
    desc: "+91 8279-251471",
  },
  {
    icon: <HiMail />,
    title: "Mail Us",
    desc: "archyogam@gmail.com",
  },
  {
    icon: <HiDotsCircleHorizontal />,
    title: "Our Website",
    desc: "www.archyogam.com",
  },
];
