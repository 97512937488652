import "./CarouselSlider.css";
import Carousel from "react-bootstrap/Carousel";
import ButtonComponent from "../Button/Button";
import { Link } from "react-router-dom";

const CarouselSlider = ({ carouselData }) => {
  return (
    <Carousel fade className="carousel main-carousel" indicators={false}>
      {carouselData &&
        Array.isArray(carouselData) &&
        carouselData.map((item) => {
          return (
            <Carousel.Item key={item.image} className="slide-item">
              <img
                className="d-block w-100"
                src={item.image}
                alt={item.title}
              />
              <Carousel.Caption className="slide-item-caption">
                <h3>
                  {item.title.split(" ")[0]}&nbsp;
                  {item.title.split(" ")[1]}&nbsp;
                  <br />
                  <span>{item.title.split(" ")[2]}</span>
                  {item.title.split(" ")[3]}
                </h3>
                <p>{item.subtitle}</p>
                <div className="btn-box">
                  <Link to={item.slug}>
                    <ButtonComponent linkTitle={item.linkTitle} />
                  </Link>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

export default CarouselSlider;
