import { SiHomeassistantcommunitystore } from "react-icons/si";

export const featuresData = [
  {
    icon: <SiHomeassistantcommunitystore />,
    title: "Exclusive Design",
    subtitle:
      "Unique and functionality oriented designs which go hand in hand with contemporary architecture",
  },
  {
    icon: <SiHomeassistantcommunitystore />,
    title: "Professional Team",
    subtitle:
      "A strong network of skilled labours and vendors , to provide you prime services",
  },
];

export const listboxData = [
  {
    titleOne: "Contextually evolving spaces",
    titleTwo: "Minimalistic designs",
  },
  {
    titleOne: "Luxurious mix of materials and textures",
    titleTwo: "Functionality oriented designs",
  },
];
