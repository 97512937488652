import One from "../assets/images/1.jpg";
import Two from "../assets/images/2.jpg";
import Three from "../assets/images/3.jpg";

export const carouselData = [
  {
    image: One,
    title: "World class interior design",
    slug: "/architecture",
    subtitle:
      "We have almost 3+ years of experience for providing interior & Architectural services solutions",
    linkTitle: "Read More",
  },
  {
    image: Two,
    title: "Providing Best home Decore",
    slug: "/modular-kitchen",
    subtitle:
      "We take pride in offering a comprehensive range of home decor services that cater to our clients",
    linkTitle: "Read More",
  },
  {
    image: Three,
    title: "We Provide kitchen design",
    slug: "/interior-work",
    subtitle:
      "We offer kitchen design services that help clients create functional and stylish kitchens.",
    linkTitle: "Read More",
  },
];
