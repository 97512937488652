import archimain from "../assets/images/architect-drawing-plan-with-pencil.jpg";
import archisec from "../assets/images/worker-drawing-blueprint.jpg";
import residentialmain from "../assets/images/luxury-bedroom-suite-resort-high-rise-hotel-with-working-table.jpg";
// import residentialsec from "../assets/images/resid.jpg";
// import commmain from "../assets/images/comm1.avif";
import commsec from "../assets/images/comm2.avif";
// import modmain from "../assets/images/mod1.avif";
// import modsec from "../assets/images/mod2.avif";
import furnituremain from "../assets/images/furni1.avif";
import furnituresec from "../assets/images/furni2.avif";

export const serviceTypeData = [
  {
    name: "Architecture",
    type: "Architecture",
    mainImg: archisec,
    secImg: archimain,
    basicInfo:
      "Architectural services help clients to create a vision for their project, ensuring that all design decisions are based carefully considering the user's lifestyle. This includes noteworthy advice on the functionality of the space , climatology , materials, and construction techniques. We help our clients in making their dreams to reality, from the conceptual design to construction phase.",
    highlight: "Architecture Services: Vision to Construction",
    desc: "Our architecture services are designed to help the client by understanding their psychology and lifestyle. We offer them design options which are functional and space optimising. We work with the clients to develop a concept, and then guide them through the entire process. In the whole process, we try to educate them about the materials, construction techniques that are in context with their project. We provide high quality of work, and make sure that the end result meets the client's expectations.With our architecture services, we help clients bring their project to life.",
    faq: [
      {
        ques: "What kind of projects do you provide architecture services for?",
        ans: "We provide services for residential, commercial, educational and industrial projects.",
      },
      {
        ques: "What is included in your architecture services?",
        ans: "Our architecture services provide note worthy advice on design, materials, and construction methods.",
      },
      {
        ques: "How do you ensure that the end result meets the client's vision and budget?",
        ans: "We understand client's taste & lifestyle first & then design functionality as per users lifestyle.",
      },
    ],
    listOptions: [
      "Services for residential, commercial, educational and industrial projects",
      "Note Worthy advice on design.",
      "From conceptual design to construction",
      "Attention to detail and budget constraints",
    ],
  },
  {
    name: "Interior Design ",
    type: "Interior",
    mainImg: residentialmain,
    secImg: commsec,
    basicInfo:
      "Interior design services assist clients with an uplifted aesthetics and upgraded living standard of the pre constructed space. Our experienced designers guide you through the entire process, from best space utilization as per your lifestyle to colour palette of furnishings..",
    highlight: "Interior Design Services: Creating a Home",
    desc: "Our interior design services are designed in such a way that we work for achieving cohesive design, that is from floor to furnishings. We understand the taste of the user and create a conceptual layout followed by a mood board and colour palette for the space. Since interior is a LUXURY, we guide them about the different materials , their finishes, installation and the price range. If there is any budget constraint, we try our level best to work within that and provide them the best quality outcome. With our interior design service we upgrade the spacein terms of aesthetics and space optimisation.",
    faq: [
      {
        ques: "What kind of projects do you provide interior design services for?",
        ans: "We provide interior design services for all building typologies.",
      },
      {
        ques: "What is included in your interior design services?",
        ans: "Our interior design services provide note worthy advice on design, materials, and decoration methods. We also offer guidance from conceptual design to the interior styling phase..",
      },
      {
        ques: "How do you ensure that the end result meets the client's vision and budget?",
        ans: "We work closely with our clients to ensure that their vision and budget are taken into consideration throughout the entire process.",
      },
    ],
    listOptions: [
      "Services for residential, commercial, educational and industrial projects",
      "Note Worthy advice on design.",
      "From conceptual design to the construction phase",
      "Attention to detail and budget constraints",
    ],
  },
  // {
  //   name: "Commercial Interior Design",

  //   mainImg: commmain,
  //   secImg: commsec,
  //   basicInfo:
  //     "Commercial interior design services provide note worthy advice on layout, materials, and furniture for commercial spaces. Our experienced designers help clients bring their vision to life, from conceptual design to the construction phase, ensuring that the end result meets their vision and budget.",
  //   highlight: "Commercial Interior Design Services: From Vision to Reality",
  //   desc: "Our commercial interior design services help clients create a vision for their project, ensuring that all design decisions are based on carefully considered objectives. Our experienced designers provide note worthy advice on layout, materials, and furniture for commercial spaces. We help our clients realize their goals, from conceptual design to the construction phase, ensuring that the end result meets their vision and budget. With our commercial interior design services, we help clients bring their project to life.",
  //   faq: [
  //     {
  //       ques: "What kind of projects do you provide commercial design services for?",
  //       ans: "We provide commercial interior design services for commercial spaces.",
  //     },
  //     {
  //       ques: "What is included in your commercial design services?",
  //       ans: "Our commercial interior design services provide note worthy advice on layout, materials, and furniture. We also offer guidance from conceptual design to the construction phase.",
  //     },
  //     {
  //       ques: "How do you ensure that the end result meets the client's vision and budget?",
  //       ans: "We work closely with our clients to ensure that their vision and budget are taken into consideration throughout the entire process. We also pay attention to detail and budget constraints to make sure the end result meets their expectations.",
  //     },
  //   ],
  //   listOptions: [
  //     "Design services for commercial spaces",
  //     "note worthy advice on design",
  //     "From conceptual design to the construction phase",
  //     "Attention to detail and budget constraints",
  //   ],
  // },
  // {
  //   name: "Modular Kitchen Interior Design ",

  //   mainImg: modmain,
  //   secImg: modsec,
  //   basicInfo:
  //     "Modular kitchen interior design services create stylish and functional solutions for your home. Our experienced designers provide advice on design, layout, and materials, while taking into consideration your lifestyle and budget. With our services, you can ensure that your kitchen looks great and works perfectly.",
  //   highlight:
  //     "Modular Kitchen Interior Design Services: Design Solutions for Your Home",
  //   desc: "Our modular kitchen interior design services offer creative solutions for your home. We provide advice on the most suitable design elements and materials for your kitchen, as well as guidance on the best layout to fit your lifestyle and space. Our experienced designers create a kitchen design that meets your standards of style, functionality, and budget. With our modular kitchen interior design services, you can make sure your kitchen looks great and works perfectly.",
  //   faq: [
  //     {
  //       ques: "What kind of services do you provide for modular kitchen interior design?",
  //       ans: "We provide advice on design elements and materials, as well as guidance on the best layout to fit your lifestyle and space.",
  //     },
  //     {
  //       ques: "How do you make sure the kitchen design meets the client's standards?",
  //       ans: "Our experienced designers take into consideration the client's style, functionality, and budget when creating a kitchen design.",
  //     },
  //     {
  //       ques: "Do you provide assistance with the installation process?",
  //       ans: "Yes, we provide guidance on the installation process, ensuring that all components are properly installed for a flawless kitchen.",
  //     },
  //   ],
  //   listOptions: [
  //     "Creative solutions for your home       ",
  //     "Advice on design elements and materials",
  //     "Guidance on best layout for lifestyle and space ",
  //     " Consideration of style, functionality, and budget",
  //   ],
  // },
  {
    name: "Furniture  Design ",
    type: "Furniture",
    mainImg: furnituremain,
    secImg: furnituresec,
    basicInfo:
      "In our furniture Design services ,  we customise furniture of every site as per the size and vibe of the space. We create stylish high end furniture that just go hand in hand with the interior design of the space. Furniture can either make or break the space , so you need to be very wise with the furniture selection.",
    highlight: "Furniture Design Services: Creating Your Dream Home",
    desc: "Our furniture design services simplifies furniture selection phase for the clients. We customise all our furniture on site considering the dimensions of the space, colour palette and mood of the space. We ensure high quality of stuff from raw material to the finishing material. Our skilled labour do the implementation and we keep a quality check on everything , we also deal in modular furniture , which is our premium range in furniture. Furniture should always be cohesive with the design and it should should look effortlessly put togehter.",
    faq: [
      {
        ques: "What kind of projects do you provide furniture design services for?",
        ans: "We provide furniture design services for residential projects.",
      },
      {
        ques: "What is included in your furniture design services?",
        ans: "Our furniture design services provide noteworthy advice on design , material , layout and space utilisation. We keep quality check during the implementation phase.",
      },
      {
        ques: "How do you ensure that the end result meets the client's vision and budget?",
        ans: "We work closely with our clients to ensure that their vision and budget are taken into consideration throughout the entire process. We also pay attention to detail and budget constraints to make sure the end result meets their expectations.",
      },
    ],
    listOptions: [
      "Services for residential, commercial, educational and industrial projects",
      "Note Worthy advice on design.",
      "From conceptual design to the construction phase",
      "Attention to detail and budget constraints",
    ],
  },
];
