import { AiFillFormatPainter } from "react-icons/ai";
import { GiArchBridge, GiFurnace } from "react-icons/gi";
import { MdStyle } from "react-icons/md";

export const ServiceCardData = [
  {
    icon: <GiArchBridge />,
    heading: "Architecture design",
    desc: `Archyogam believe in contextual design.Each design takes cognates of the climate and integrates
    sustainability in cohesive way. This ideology is reflected in the extensive work done by the firm in the 3
    years since its inception.`,
    route: "",
    count: "01",
  },
  {
    icon: <AiFillFormatPainter />,
    heading: "Interior design",
    desc: "Archyogam is known for simplicity in design approach, We juxtaposes the orthogonal nature of clean lines with a luxurious mix of materials colours and texture in order to create a distinct look for each project.",
    route: "",
    count: "02",
  },
  {
    icon: <GiFurnace />,
    heading: "Furniture design",
    desc: "To make our design cohesive and stand out , we design our own furniture from scratch on site itself , we make sure that we provide the best quality of furniture in the given budget. We  no space is too small to be designed.",
    route: "",
    count: "03",
  },
  {
    icon: <MdStyle />,
    heading: "Interior styling",
    desc: "Our work does not end at furniture design , we also do interior styling for the projects that we do from scratch , which is cherry on the cake for our clients, as it helps our clients to readily move in without thinking out the small details.",
    route: "",
    count: "04",
  },
];
