import "./Projects.css";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import ProjectItem from "../../components/LatestProjects/ProjectItem/ProjectItem";
import { projectsList } from "../../services/Projects.services";
import { ContactBanner } from "../../components/ContactBanner/ContactBanner";
import Heading from "../../components/Heading/Heading";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { TestimonialCardData } from "../../services/TestimonialCard.service";
import TestimonialCard from "../../components/Testimonials/TestimonialCard/TestimonialCard";
import {
  responsiveCarousal,
  visibleSlide,
} from "../../utils/responsiveCarousel";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase.config";

const Projects = () => {
  const [projectsData, setProjectsData] = useState([]);
  const getData = async () => {
    const blogRef = collection(db, "Projects");
    const querySnapshot = await getDocs(blogRef);
    setProjectsData(querySnapshot.docs);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BreadCrumb link="Projects" heading="Projects" subheading="Home" />
      <section className="section-content">
        <div className="container">
          <div className="row">
            {projectsData &&
              Array.isArray(projectsData) &&
              projectsData.map((item, index) => {
                const finalItem =
                  item?._document?.data?.value?.mapValue?.fields;
                return (
                  <div
                    className="col-md-3 col-sm-6 mb-4"
                    key={item.img + index}
                  >
                    <ProjectItem
                      item={finalItem}
                      length={projectsData.length}
                    />
                  </div>
                );
              })}
          </div>
          {/* <div className="row">
            {projectsList &&
              Array.isArray(projectsList) &&
              projectsList.map((item, index) => {
                return (
                  <div className="col-md-3 col-sm-6" key={item.img + index}>
                    <ProjectItem item={item} />
                  </div>
                );
              })}
          </div> */}
        </div>
      </section>
      <ContactBanner />
      <section className="project-testimonial">
        <Heading
          heading="TESTIMONIALS"
          subHeading1="What Our"
          highlight="Client's"
          subHeading2="Say"
        />
        <div className="container">
          <div className="row">
            <CarouselProvider
              visibleSlides={visibleSlide(3)}
              totalSlides={6}
              step={1}
              naturalSlideWidth={400}
              naturalSlideHeight={500}
              infinite={true}
            >
              <Slider>
                {TestimonialCardData &&
                  Array.isArray(TestimonialCardData) &&
                  TestimonialCardData.map((item, i) => {
                    return (
                      <Slide
                        key={item.name + i}
                        index={i}
                        style={{
                          paddingBottom: `${responsiveCarousal()}`,
                        }}
                      >
                        <TestimonialCard item={item} />
                      </Slide>
                    );
                  })}
              </Slider>
            </CarouselProvider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
