export const RangeSliderProps = [
  {
    defaultValue: "85",
    Label: "Architecture",
  },
  {
    defaultValue: "96",
    Label: "Interior",
  },
  {
    defaultValue: "75",
    Label: "Furniture",
  },
];

export const listItems = [
  {
    data: "Contextually evolving spaces",
  },
  {
    data: "Minimalistic designs",
  },
  {
    data: "Luxurious mix of materials, colours and textures",
  },
  {
    data: "Functionality oriented designs",
  },
];
