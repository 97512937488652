import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import ButtonComponent from "../../components/Button/Button";
import Heading from "../../components/Heading/Heading";
import { contactPageData } from "../../services/Contact.service";
import { SocialData } from "../../services/footer.service";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();
    const res = await emailjs
      .sendForm(
        "service_216r2xl",
        "template_fv4r9ql",
        form.current,
        "HoAMckPhnbGCobNKT"
      )
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
    if (res) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  };
  return (
    <>
      <BreadCrumb heading="Contact Us" subheading="Home" link="Contact Us" />
      <section class="contact-section">
        <div class="container">
          <div class="row mrb-80">
            <div class="col-md-12 col-lg-12 col-xl-4 heading-social-box">
              <Heading
                heading=" Get In Touch"
                subHeading1="Have Any Questions?"
              />
              <ul class="social-list">
                {SocialData &&
                  Array.isArray(SocialData) &&
                  SocialData.map((item) => {
                    return (
                      <li className="socialmediaIcons p-0" key={item.title}>
                        <a href={item.url} target="blank">
                          {item.icon}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-8">
              <div class="row">
                {contactPageData &&
                  Array.isArray(contactPageData) &&
                  contactPageData.map((item) => {
                    return (
                      <div class="col-lg-6 col-xl-6">
                        <div class="contact-block">
                          <div class="contact-icon">{item.icon}</div>
                          <div class="contact-details">
                            <h5 class="icon-box-title">{item.title}</h5>
                            <p>{item.desc}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="contact-form">
                <form ref={form} onSubmit={sendEmail}>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mrb-25">
                        <input
                          type="text"
                          required
                          placeholder="Name"
                          class="form-control"
                          name="from_name"
                          value={formData.name}
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mrb-25">
                        <input
                          required
                          type="text"
                          placeholder="Phone"
                          class="form-control"
                          name="from_phoneNumber"
                          value={formData.phone}
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mrb-25">
                        <input
                          required
                          type="email"
                          placeholder="Email"
                          class="form-control"
                          name="from_email"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mrb-25">
                        <textarea
                          rows="5"
                          placeholder="Messages"
                          class="form-control"
                          name="message"
                          value={formData.message}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="form-group">
                        <button type="submit">
                          <ButtonComponent linkTitle="Submit Now" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="mapouter fixed-height">
                <div class="gmap_canvas">
                  <iframe
                    title="map"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3537.9736628801297!2d76.59722941541722!3d27.532277339769898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39729a34358ec27d%3A0x63fd717642aad174!2s4%2C%205%2C%20Vivekanand%20Nagar%2C%20Vivekanad%20Nagar%2C%20Aravali%20Vihar%2C%20Alwar%2C%20Rajasthan%20301001!5e0!3m2!1sen!2sin!4v1675951855595!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <a href="https://www.whatismyip-address.com">o</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
