import {
  GrFacebookOption,
  GrPinterest,
  GrInstagram,
  GrYoutube,
} from "react-icons/gr";

export const FooterData = {
  companyName: "Achryogam",
  year: "2023",
  phoneNumber: "8279-251471",
  emailAddress: "archyogam@gmail.com",
  domainName: "www.archyogam.com",
  companyAddress: "4/5, Vivekanand Nagar , Alwar ",
};

export const SocialData = [
  {
    title: "facebook",
    url: "https://www.facebook.com/archyogam-102002161949500",
    icon: <GrFacebookOption />,
  },
  { title: "pinterest", url: "https://pin.it/3vHB0kD", icon: <GrPinterest /> },
  {
    title: "insta",
    url: "https://instagram.com/archyogam?igshid=YmMyMTA2M2Y=",
    icon: <GrInstagram />,
  },
  {
    title: "youtube",
    url: "https://www.youtube.com/@Archyogam",
    icon: <GrYoutube />,
  },
];
