import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
export const categories = [
  {
    title: "Architectural design",
    icon: <MeetingRoomOutlinedIcon />,
  },
  {
    title: "Residential Interior design",
    icon: <CountertopsOutlinedIcon />,
  },
  {
    title: "Commercial interior design",
    icon: <GroupsOutlinedIcon />,
  },
  {
    title: "Modular  kitchen",
    icon: <AssuredWorkloadOutlinedIcon />,
  },
  {
    title: "Furniture design",
    icon: <CreditScoreOutlinedIcon />,
  },
  {
    title: "Low cost",
    icon: <MilitaryTechOutlinedIcon />,
  },
];
