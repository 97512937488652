import "./ProjectDetails.css";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { Check, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
const ProjectDetails = (props) => {
  const { fifthImage, firstImage, fourthImage, secondImage, thirdImage } =
    props.item;
  let sliderImage = [
    firstImage?.stringValue,
    fifthImage?.stringValue,
    fourthImage?.stringValue,
    secondImage?.stringValue,
    thirdImage?.stringValue,
  ];

  const {
    basicInfo,
    budget,
    categories,
    challenge,
    clientName,
    primaryImage,
    projectDate,
    result,
    projectTitle,
    secondaryImage,
    pointOne,
    pointTwo,
    pointThree,
    pointFour,
  } = props.item;
  return (
    <>
      <BreadCrumb
        link={projectTitle?.stringValue}
        heading="Project Details"
        subheading="Home"
      />
      <section className="project-details-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="project-thumb">
                <img src={primaryImage?.stringValue} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-10 mrt--110 mrt-sm-30">
              <div className="project-sidebar-widget">
                <div className="project-sidebar">
                  <ul className="list project-info-list">
                    <li>
                      <span className="title">Project Date:</span>{" "}
                      {projectDate?.stringValue}
                    </li>
                    <li>
                      <span className="title">Location:</span>{" "}
                      {clientName?.stringValue}
                    </li>
                    <li>
                      <span className="title">Categories:</span>
                      {categories?.stringValue}
                    </li>
                    {/* <li>
                      <span className="title">Budgets:</span>
                      {budget?.stringValue}
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mrb-40">
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="project-detail-text mrb-md-25">
                <h3 className="project-details-title mrb-20">
                  Project Basic Info
                </h3>
                <div className="project-details-content">
                  <p className="mrb-0 text-justify">{basicInfo?.stringValue}</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 d-flex flex-sm-column list-column">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <ul className="order-list primary-color">
                  <li>
                    <Check />
                    {pointOne?.stringValue}
                  </li>
                  <li>
                    <Check />
                    {pointTwo?.stringValue}
                  </li>
                  <li>
                    <Check />
                    {pointThree?.stringValue}
                  </li>
                  <li>
                    <Check />
                    {pointFour?.stringValue}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mrb-10 mrb-lg-40">
            {/* <div className="col-xl-7 col-lg-12">
              <h3 className="mrb-20">Project Challenge</h3>
              <p className="mrb-lg-30 text-justify">{challenge?.stringValue}</p>
            </div> */}
            <div className="col-xl-5 col-lg-12 mb-4">
              {/* <img
                className="small-img"
                src={secondaryImage?.stringValue}
                alt=""
              /> */}
              <Carousel fade className="carousel-projects" indicators={false}>
                {sliderImage &&
                  Array.isArray(sliderImage) &&
                  sliderImage.map((item) => {
                    return (
                      <Carousel.Item key={item} className="">
                        <img className="d-block w-100" src={item} alt={item} />
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
          </div>
          <div className="row mrb-15">
            <div className="col-xl-12">
              <h3 className="mrb-20 result-heading">Project Result</h3>
              <p className="text-justify">{result?.stringValue}</p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-12">
              <div className="project-detail-text">
                <div className="project-details-content">
                  <div className="single-post-navigation mrt-20">
                    <div className="navigation-links">
                      {/* <div className="nav-previous f-left f-left-sm-none mrb-sm-15">
                        {parseInt(props?.length) > 1 && (
                          <Link
                            to={`/projects/${parseInt(props?.length) - 1}`}
                            className="cs-btn-one btn-sm"
                          >
                            <ChevronLeft />
                            Prev Project
                          </Link>
                        )}
                      </div> */}
                      <div className="nav-next text-start text-sm-end">
                        {/* {parseInt(props?.length) < length && ( */}
                        <Link to={`/projects`} className="cs-btn-one btn-sm">
                          Go Back To All Projects
                        </Link>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectDetails;
