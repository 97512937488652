import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./Header.css";
import CallIcon from "@mui/icons-material/Mail";
import { headerData, NavBarItems } from "../../services/header.service";

import MenuIcon from "@mui/icons-material/Menu";
import { BiChevronDownSquare } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { IoCall, IoMail } from "react-icons/io5";
import { SocialData } from "../../services/footer.service";
const Header = () => {
  const [header, setHeader] = useState("");
  const { logo, phoneNumber } = headerData;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobilePopup, setMobilePopup] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const listenScrollEvent = (event) => {
    if (window.scrollY < 53) {
      return setHeader("");
    } else if (window.scrollY > 50) {
      return setHeader("headerscroll");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const ItemsList = ({ id, name, submenu, slug }) => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
      <>
        <ListItem className="listItem">
          <Link
            to={slug}
            className="flex w-full"
            onClick={() => {
              if (submenu) {
                submenu && setOpenMenu(!openMenu);
              } else {
                setMobilePopup(false);
              }
            }}
          >
            <ListItemText primary={name} sx={{ color: "white" }} />
            {submenu && <BiChevronDownSquare color="white" fontSize="1.5rem" />}
          </Link>
        </ListItem>
        <Divider sx={{ borderColor: "white" }} />
        {submenu &&
          openMenu &&
          submenu.map((submenuItems, index) => {
            return (
              <div className="ml-5" key={index}>
                <ItemsList {...submenuItems} />
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Box className="headerWrapper">
        <AppBar position="static" className={header}>
          <Toolbar className="headerContainer">
            <div>
              <Link to="/">
                <img src={logo} alt="website logo" className="headerLogo" />
              </Link>
            </div>
            <div className="gap-5 headerTabs">
              {NavBarItems &&
                Array.isArray(NavBarItems) &&
                NavBarItems.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={item?.slug}
                      className={`headerLink ${
                        item?.submenu && "menuItemHeaderLink"
                      }`}
                      onClick={item?.submenu && handleClick}
                    >
                      {item?.name}
                    </Link>
                  );
                })}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {NavBarItems &&
                  NavBarItems.map((mainItem) => {
                    return (
                      <div key={mainItem.id}>
                        {mainItem.submenu &&
                          mainItem.submenu.map((subItem) => {
                            return (
                              <MenuItem
                                key={subItem.name}
                                className="menuItemTextColor"
                              >
                                <Link
                                  to={`/${subItem.slug}`}
                                  className="text-black"
                                >
                                  {subItem?.name}
                                </Link>
                              </MenuItem>
                            );
                          })}
                      </div>
                    );
                  })}
              </Menu>
            </div>
            <div className="flex gap-4">
              <div className="w-12 h-12 flex items-center justify-center rounded-full bg-default callAnytimeIcon">
                <a href="mailto:archyogam@gmail.com">
                  <CallIcon />
                </a>
              </div>
              <div className="flex justify-center flex-col gap-1 callAnytime">
                <p className="text-sm m-0 callAnytimeText leading-3">Mail Us</p>
                <a href="mailto:archyogam@gmail.com" className="headerLink">
                  {phoneNumber}
                </a>
              </div>
              <div
                className="w-9 h-9 flex items-center justify-center rounded-full bg-default hamburgerMenu"
                onClick={() => {
                  setMobilePopup(() => !mobilePopup);
                }}
              >
                <MenuIcon />
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      {mobilePopup && (
        <Dialog
          className="mobile-menu-dialogue"
          fullScreen
          open={mobilePopup}
          sx={{
            backgroundColor: "#252531",
          }}
        >
          <AppBar sx={{ backgroundColor: "#252531" }} className="mobile-appbar">
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Link to="/">
                  <img src={logo} alt="website logo" width={80} height={80} />
                </Link>
              </div>
              <IconButton
                onClick={() => setMobilePopup(false)}
                sx={{
                  width: "35px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "50px",
                  backgroundColor: "#b98e75",
                }}
              >
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List
            className={`${mobilePopup ? "mobile-animate" : "dont-animate"}`}
            sx={{
              backgroundColor: "#252531",
              padding: "60px 10px 30px 10px",
            }}
          >
            {NavBarItems &&
              Array.isArray(NavBarItems) &&
              NavBarItems.map((Item, index) => {
                return <ItemsList key={index} {...Item} />;
              })}
            <ul className="my-10 flex flex-column gap-3">
              <li className="flex items-center gap-2.5">
                <MdLocationOn color="#B98E75" />
                <span className="text-sm text-white">
                  4/5, Vivekanand Nagar, Alwar
                </span>
              </li>
              <li className="flex items-center gap-2.5">
                <IoMail color="#B98E75" />
                <a
                  href="mailto:archyogam@gmail.com"
                  className="text-sm text-white"
                >
                  archyogam@gmail.com{" "}
                </a>
              </li>
              <li className="flex items-center gap-2.5">
                <IoCall color="#B98E75" />
                <a href="tel:8279-251471" className="text-sm text-white">
                  {" "}
                  +91-8279-251471
                </a>
              </li>
            </ul>
            <ul className="flex justify-start gap-3">
              {SocialData &&
                Array.isArray(SocialData) &&
                SocialData.map((item) => {
                  return (
                    <li className="mobileSocialMediaIcons" key={item.title}>
                      <a href={item.url} target="blank">
                        {item.icon}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </List>
        </Dialog>
      )}
    </>
  );
};

export default Header;
