import "../../animation.css";
import "./CompanyServices.css";
import lamp from "../../assets/images/lamp.png";
import React from "react";
import Heading from "../Heading/Heading";

const CompanyServices = () => {
  return (
    <div className="services-container">
      <div className="container">
        <div className="lamp-box">
          <img src={lamp} alt="lamp" />
        </div>
        <div className="service-container-header">
          <Heading
            heading="Our Services"
            subHeading1="We Provide"
            subHeading2="Design Services"
            highlight="Cohesive"
          />
          <p>
            At Archyogam we believe in cohesive designs , so we do take up
            projects from scratch that is from foundation to furnishings.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyServices;
