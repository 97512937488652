import "./Heading.css";

const Heading = ({ heading, subHeading1, subHeading2, highlight }) => {
  return (
    <div className="heading-box">
      <h5 className="heading">{heading}</h5>
      <h2>
        {subHeading1} <span>{highlight}</span> {subHeading2}
      </h2>
    </div>
  );
};

export default Heading;
