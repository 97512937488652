import Heading from "../Heading/Heading";
import "../../animation.css";
import "./Testimonial.css";
import testimonailObj from "../../assets/images/testimonial-obj1.png";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import "pure-react-carousel/dist/react-carousel.es.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { TestimonialCardData } from "../../services/TestimonialCard.service";
import {
  responsiveCarousalnew,
  visibleSlidenew,
} from "../../utils/responsiveCarousel";

const Testimonial = () => {
  return (
    <div className="testimonial-container">
      <div className="container">
        <div className="testimonial-section-obj1">
          <img src={testimonailObj} alt="testimonial-obj" />
        </div>
        <div className="col-md-12 heading-container">
          <Heading
            heading="Testimonials"
            subHeading1="What Our"
            highlight="Client's"
            subHeading2="Say?"
          />
          <p>
            We Take Pride In Our Work And Are Committed To Making Each Of Our
            Clients Happy{" "}
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <CarouselProvider
          visibleSlides={visibleSlidenew(2)}
          totalSlides={6}
          step={1}
          naturalSlideWidth={400}
          naturalSlideHeight={500}
          infinite={true}
        >
          <Slider>
            {TestimonialCardData &&
              Array.isArray(TestimonialCardData) &&
              TestimonialCardData.map((item, i) => {
                return (
                  <Slide
                    key={item.name + i}
                    index={i}
                    style={{
                      paddingBottom: `${responsiveCarousalnew()}`,
                    }}
                  >
                    <TestimonialCard item={item} />
                  </Slide>
                );
              })}
          </Slider>
          <ButtonBack className="backbtn">
            <BsChevronLeft />
          </ButtonBack>
          <ButtonNext className="nextbtn">
            <BsChevronRight />
          </ButtonNext>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default Testimonial;
