import "./ServiceCard.css";
import { ServiceCardData } from "../../services/ServiceCard.service";

const ServiceCard = () => {
  return (
    <>
      {ServiceCardData &&
        Array.isArray(ServiceCardData) &&
        ServiceCardData.map((item) => {
          return (
            <div className=" col-lg-3 col-md-6 col-sm-6" key={item.heading}>
              <div className="service">
                {item.icon} <div className="services-count">{item.count}</div>
                <h4 className="service-title">{item.heading}</h4>
                <p className="service-description">{item.desc}</p>
                <div className="services-link">
                  {/* <a className="text-btn" href="service-life-insurance.html">
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
          );
        })}
      <div className="service-inner-obj"></div>
    </>
  );
};

export default ServiceCard;
