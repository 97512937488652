import React from "react";
import NumberCounter from "number-counter";
import "../../animation.css";
import "./CompanyFacts.css";
import { companyData } from "../../services/ComapnyFacts.service";
import animatedImage from "../../assets/images/funfact-obj1.png";
import secondAnimatedImages from "../../assets/images/funfact-obj2.png";
import thirdAnimatedImages from "../../assets/images/funfact-obj3.png";

const CompanyFacts = () => {
  return (
    <section className="company-facts relative">
      <div className="flex gap-10  items-center companyData">
        {companyData &&
          Array.isArray(companyData) &&
          companyData.map((data) => {
            return (
              <div key={data.text} className="flex gap-4 companyItems relative">
                <span>
                  <NumberCounter
                    end={data.counterEnd}
                    delay={data.delayOfCounter}
                    className="counter"
                  />
                </span>
                <span className="text-2xl  companyTitle">{data.text}</span>
                <span className="factsIcon">{data.icon}</span>
              </div>
            );
          })}
      </div>
      <div className="absolute bottom-0 animatedImage">
        <img src={secondAnimatedImages} alt="" />
      </div>
      <div className="absolute bottom-0 animatedImage">
        <img src={animatedImage} alt="" />
      </div>
      <div className="absolute right-0 bottom-0 animatedImage">
        <img src={thirdAnimatedImages} alt="" />
      </div>
    </section>
  );
};

export default CompanyFacts;
