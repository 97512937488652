import { Link } from "react-router-dom";
import "./BreadCrumb.css";
const BreadCrumb = ({ link, heading, subheading }) => {
  return (
    <section className="page-title-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="breadcrumb-area">
              <h2 className="page-title">{heading}</h2>
              <ul className="breadcrumbs-link">
                <li>
                  <Link to="/">{subheading}</Link>
                </li>
                <li>
                  <span>&#8594;</span>
                </li>
                <li className="active">{link}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
